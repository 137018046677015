import { HYDRATE } from "next-redux-wrapper";
import { ActionsCheckStock } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeCheckStock(state = initialState, action) {

	switch (action.type) {

	case HYDRATE: {

		return { ...state, ...action.payload.storeCheckStock };

	}
	case ActionsCheckStock.LOAD_GET_CHECKSTOCK:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsCheckStock.RES_GET_CHECKSTOCK:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsCheckStock.ERR_GET_CHECKSTOCK:
		return {
			...state, data: {}, status: "fetch_error", message: "Error...",
		};
	default: {

		return {
			...state,
		};

	}

	}

}

export default storeCheckStock;
