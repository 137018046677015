// Actions Homepage
export const ActionsHomepage = {
	GET_HOMEPAGE: "get_homepage",
	LOAD_GET_HOMEPAGE: "load_get_homepage",
	RES_GET_HOMEPAGE: "result_get_homepage",
	ERR_GET_HOMEPAGE: "error_get_homepage",
	HYDRATE: "HYDRATE",
};

// Function if FailureHomepage
export function failureHomepage(error) {

	return {
		type: ActionsHomepage.ERR_GET_HOMEPAGE,
		error,
	};

}

// Function if LoadHomepage
export function loadHomepage(params = {}) {

	return { type: ActionsHomepage.GET_HOMEPAGE, ...params };

}

// Function if LoadGetHomepage
export function loadGetHomepage(params = {}) {

	return { type: ActionsHomepage.LOAD_GET_HOMEPAGE, ...params };

}

// Function if LoadHomepageSuccess
export function loadHomepageSuccess(data) {

	return {
		type: ActionsHomepage.RES_GET_HOMEPAGE,
		data,
	};

}
