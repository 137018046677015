// Actions LoginPopup
export const ActionsLoginPopup = {
	GET_LOGIN_POPUP: "get_login_popup",
	LOAD_GET_LOGIN_POPUP: "load_get_login_popup",
	RES_GET_LOGIN_POPUP: "result_get_login_popup",
	ERR_GET_LOGIN_POPUP: "error_get_login_popup",
	HYDRATE: "HYDRATE",
};

// LOCAL API PRODUCTS
export const API_CART_TOTAL = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/total/badge-count`;

// Function if FailureLoginPopup
export function failureLoginPopup(error) {

	return {
		type: ActionsLoginPopup.ERR_GET_LOGIN_POPUP,
		error,
	};

}

// Function if LoadLoginPopup
export function loadLoginPopup(params = {}) {

	return { type: ActionsLoginPopup.GET_LOGIN_POPUP, ...params };

}

// Function if LoadLoginPopupSuccess
export function loadLoginPopupSuccess(data) {

	return {
		type: ActionsLoginPopup.RES_GET_LOGIN_POPUP,
		data,
	};

}
