// Actions Promotion
export const ActionsCheckFlashSalePDP = {
	GET_CHECKFLASHSALEPDP: "get_checkflashsalepdp",
	LOAD_GET_CHECKFLASHSALEPDP: "load_get_checkflashsalepdp",
	RES_GET_CHECKFLASHSALEPDP: "result_get_checkflashsalepdp",
	ERR_GET_CHECKFLASHSALEPDP: "error_get_checkflashsalepdp",
	HYDRATE: "HYDRATE",
};

// LOCAL API PROMOTION
export const API_PRODUCT = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-product`;

// Function if failureCheckFlashSalePDP
export function failureCheckFlashSalePDP(error) {

	return {
		type: ActionsCheckFlashSalePDP.ERR_GET_CHECKFLASHSALEPDP,
		error,
	};

}

// Function if loadCheckFlashSalePDP
export function loadCheckFlashSalePDP(params = {}) {

	return { type: ActionsCheckFlashSalePDP.GET_CHECKFLASHSALEPDP, ...params };

}

// Function if LoadGetAddres
export function loadGetCheckFlashSalePDP(params = {}) {

	return { type: ActionsCheckFlashSalePDP.LOAD_GET_CHECKFLASHSALEPDP, ...params };

}

// Function if loadCheckFlashSalePDPSuccess
export function loadCheckFlashSalePDPSuccess(data) {

	return {
		type: ActionsCheckFlashSalePDP.RES_GET_CHECKFLASHSALEPDP,
		data,
	};

}
