// Actions Product Detail
export const ActionsProductDetail = {
	GET_PRODUCT_DETAIL: "get_product_detail",
	LOAD_GET_PRODUCT_DETAIL: "load_get_product_detail",
	RES_GET_PRODUCT_DETAIL: "result_get_product_detail",
	ERR_GET_PRODUCT_DETAIL: "error_get_product_detail",
	HYDRATE: "HYDRATE",
};

// LOCAL API PRODUCT DETAIL
export const API_PRODUCT_DETAIL = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-product`;

// Function if FailureProductDetail
export function failureProductDetail(error) {

	return {
		type: ActionsProductDetail.GET_PRODUCT_DETAIL,
		error,
	};

}

// Function if LoadProductDetail
export function loadProductDetail(params = {}) {

	return { type: ActionsProductDetail.GET_PRODUCT_DETAIL, ...params };

}

// Function if LoadProductDetailSuccess
export function loadProductDetailSuccess(data) {

	return {
		type: ActionsProductDetail.RES_GET_PRODUCT_DETAIL,
		data,
	};

}
