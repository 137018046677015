// Actions Banner
export const ActionsBanner = {
	GET_BANNER: "get_banner",
	LOAD_GET_BANNER: "load_get_banner",
	RES_GET_BANNER: "result_get_banner",
	ERR_GET_BANNER: "error_get_banner",
	HYDRATE: "HYDRATE",
};

// LOCAL API BANNER
export const API_BANNER = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-banner`;

// Function if FailureBanner
export function failureBanner(error) {

	return {
		type: ActionsBanner.ERR_GET_BANNER,
		error,
	};

}

// Function if LoadBanner
export function loadBanner(params = {}) {

	return { type: ActionsBanner.GET_BANNER, ...params };

}

// Function if LoadBannerSuccess
export function loadBannerSuccess(data) {

	return {
		type: ActionsBanner.RES_GET_BANNER,
		data,
	};

}
