// Actions Brands
export const ActionsBrands = {
	GET_BRANDS: "get_brands",
	LOAD_GET_BRANDS: "load_get_brands",
	RES_GET_BRANDS: "result_get_brands",
	ERR_GET_BRANDS: "error_get_brands",
	HYDRATE: "HYDRATE",
};

// LOCAL API PRODUCTS
export const API_BRANDS = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-brands`;

// Function if FailureBrands
export function failureBrands(error) {

	return {
		type: ActionsBrands.ERR_GET_BRANDS,
		error,
	};

}

// Function if LoadBrands
export function loadBrands(params = {}) {

	return { type: ActionsBrands.GET_BRANDS, ...params };

}

// Function if LoadBrandsSuccess
export function loadBrandsSuccess(data) {

	return {
		type: ActionsBrands.RES_GET_BRANDS,
		data,
	};

}
