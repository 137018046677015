/* eslint-disable max-len */
import { call, put, takeEvery } from "redux-saga/effects";
import Env from "Consts/env";
import {
	ActionsProducts,
	API_PRODUCTS,
	failureProducts,
	loadProductsSuccess,
} from "./actions";

// Function Fetch Product
function* loadDataProducts(params = {}) {

	try {

		const endpoint = `${API_PRODUCTS}?page=${params.payload.page}&limit=${params.payload.limit}&category=${params.payload.category}&sort=${params.payload.sort}&placement=${params.payload.placement}&query=${params.payload.query}`;
		const headers = { ...Env.HEADERS };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		if (typeof response.status !== "undefined" && response.status >= 200 && response.status < 300) {

			const self = yield response.json();

			yield put(loadProductsSuccess(self));

		} else {

			throw response;

		}

	} catch (err) {

		yield put(failureProducts(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsProducts.GET_PRODUCTS, loadDataProducts);

}
