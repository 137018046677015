/* eslint-disable max-len */
import { call, put, takeEvery } from "redux-saga/effects";
import Env from "Consts/env";
import {
	ActionsBrands,
	API_BRANDS,
	failureBrands,
	loadBrandsSuccess,
} from "./actions";

// Function Fetch Product
function* loadDataBrands(params = {}) {

	try {

		const endpoint = `${API_BRANDS}?all=${params.payload.all || ""}&
			page=${params.payload.page || ""}&
			initial=${(params.payload.initial === "#" ? "%23" : params.payload.initial) || ""}&
			sort=${params.payload.sort || ""}&limit=${params.payload.limit || ""}&
			is_featured=${params.payload.is_featured || ""}`;
		const headers = { ...Env.HEADERS, slug: params.payload.slug || "" };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		if (typeof response.status !== "undefined" && response.status >= 200 && response.status < 300) {

			let self = yield response.json();

			if (params.payload.limit) {

				// slice response data to limit
				self = {
					...self,
					data: [
						...self.data.slice(0, params.payload.limit),
					],
				};

			}

			yield put(loadBrandsSuccess(self));

		} else {

			throw response;

		}

	} catch (err) {

		yield put(failureBrands(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsBrands.GET_BRANDS, loadDataBrands);

}
