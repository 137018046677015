/* eslint-disable max-len */
import { call, put, takeEvery } from "redux-saga/effects";
import Env from "Consts/env";
import {
	ActionsPromotion,
	failurePromotion,
	loadPromotionSuccess,
	loadGetPromotion,
	API_PROMOTION,
} from "./actions";

// Function Fetch Promotion
function* loadDataPromotion() {

	try {

		yield put(loadGetPromotion());

		const endpoint = API_PROMOTION;
		const headers = { ...Env.HEADERS };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		if (typeof response.status !== "undefined" && response.status >= 200 && response.status < 300) {

			const self = yield response.json();

			yield put(loadPromotionSuccess(self));

		} else {

			throw response;

		}

	} catch (err) {

		yield put(failurePromotion(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsPromotion.GET_PROMOTION, loadDataPromotion);

}
