/* eslint-disable max-len */
import {
	call, put, takeEvery,
} from "redux-saga/effects";
import Env from "Consts/env";
import {
	ActionsCheckStock,
	failureCheckStock,
	loadCheckStockSuccess,
	loadGetCheckStock,
	API_PRODUCT,
} from "./actions";

// Function Fetch ActionsCheckStock
function* loadDataActionsCheckStock(params = {}) {

	try {

		yield put(loadGetCheckStock());
		// const endpoint = `${API_GET_CHECKSTOCK}/?stockId=${params.payload.stockId}&sku=${params.payload.sku}&tokenMagento=${params.payload.tokenMagento}`;
		const endpoint = `${API_PRODUCT}/${params.sku}/price-and-stock`;
		const headers = { ...Env.HEADERS };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		if (typeof response.status !== "undefined" && response.status >= 200 && response.status < 300) {

			const self = yield response.json();
			yield put(loadCheckStockSuccess(self));

		} else {

			throw response;

		}

	} catch (err) {

		yield put(failureCheckStock(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsCheckStock.GET_CHECKSTOCK, loadDataActionsCheckStock);
	// yield all([takeEvery(ActionsCheckStock.ADD_ADDRESS, loadAddDataAddress)]);
	// yield all([takeEvery(ActionsCheckStock.DELETE_ADDRESS, loadDeleteDataAddress)]);
	// yield all([takeEvery(ActionsCheckStock.EDIT_ADDRESS, loadEditDataAddress)]);

}
