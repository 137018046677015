import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Desktop from "./desktop";
import Mobile from "./mobile";

function LoginPopup({
	isMobile, ...props
}) {

	const selectComponent = {
		true: Mobile,
		false: Desktop,
	};
	const Component = selectComponent[String(isMobile)];

	const onHideCallback = useCallback(
		props.onHide,
		[props.show],
	);
	const onButtonClickCallback = useCallback(
		props.onButtonClick,
		[props.show],
	);

	return (
		<>
			<Component
				{...props}
				onButtonClickCallback={onButtonClickCallback}
				onHideCallback={onHideCallback}
			/>
		</>
	);

}

LoginPopup.propTypes = {
	title: PropTypes.string,
	desc: PropTypes.string,
	buttonText: PropTypes.string,
	isMobile: PropTypes.bool,
	onHide: PropTypes.func,
	onButtonClick: PropTypes.func,
	show: PropTypes.bool,
	minWidth: PropTypes.string,
	zIndex: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
};

LoginPopup.defaultProps = {
	title: "Session Expired",
	desc: "Sesi sudah berakhir. Silakan login kembali.",
	buttonText: "LOGIN",
	show: false,
	isMobile: false,
	zIndex: "9999",
	minWidth: "448px",
	onHide: () => {},
	onButtonClick: () => {},
};

export default LoginPopup;
