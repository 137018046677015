/* eslint-disable max-len */
import { call, put, takeEvery } from "redux-saga/effects";
import Env from "Consts/env";
import {
	ActionsBanner,
	API_BANNER,
	failureBanner,
	loadBannerSuccess,
} from "./actions";

// Function Fetch Product
function* loadDataBanner(params = {}) {

	try {

		const endpoint = `${API_BANNER}?placement=${params.payload.placement}`;
		const headers = { ...Env.HEADERS };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		if (typeof response.status !== "undefined" && response.status >= 200 && response.status < 300) {

			const self = yield response.json();

			yield put(loadBannerSuccess(self));

		} else {

			throw response;

		}

	} catch (err) {

		yield put(failureBanner(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsBanner.GET_BANNER, loadDataBanner);

}
