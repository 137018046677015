import { HYDRATE } from "next-redux-wrapper";
import { ActionsLoginPopup } from "./actions";

const initialState = { data: { isLoginPopupShow: false }, status: "fetch_init", message: "" };

function storeLoginPopup(state = initialState, action) {

	switch (action.type) {

	case HYDRATE: {

		return { ...state, ...action.payload.storeLoginPopup };

	}
	case ActionsLoginPopup.LOAD_GET_LOGIN_POPUP:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsLoginPopup.RES_GET_LOGIN_POPUP:
		return { ...state, data: state.data.isLoginPopupShow ? { isLoginPopupShow: true } : action.data, status: "fetch_result" };
	case ActionsLoginPopup.ERR_GET_LOGIN_POPUP:
		return {
			...state, data: {}, status: "fetch_error", message: "Error...",
		};
	default: {

		return {
			...state,
		};

	}

	}

}

export default storeLoginPopup;
