/* eslint-disable max-len */
import {
	all,
	call, put, takeEvery,
} from "redux-saga/effects";
import Env from "Consts/env";
import { handle498OnClient } from "Helpers/is498";
import {
	ActionsAddress,
	failureAddress,
	loadAddressSuccess,
	loadGetAddress,
	API_GET_ADDRESS,
} from "./actions";

// Function Fetch Address
function* loadDataAddress(params = {}) {

	try {

		yield put(loadGetAddress());
		const endpoint = API_GET_ADDRESS;
		const headers = { ...Env.HEADERS, cookie: params.payload.cookie };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		if (typeof response.status !== "undefined" && response.status >= 200 && response.status < 300) {

			const self = yield response.json();
			yield all([
				put(handle498OnClient(self)),
				put(loadAddressSuccess(self)),
			]);

		} else {

			throw response;

		}

	} catch (err) {

		yield put(failureAddress(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsAddress.GET_ADDRESS, loadDataAddress);
	// yield all([takeEvery(ActionsAddress.ADD_ADDRESS, loadAddDataAddress)]);
	// yield all([takeEvery(ActionsAddress.DELETE_ADDRESS, loadDeleteDataAddress)]);
	// yield all([takeEvery(ActionsAddress.EDIT_ADDRESS, loadEditDataAddress)]);

}
