// Actions Products
export const ActionsProducts = {
	GET_SIMILAR_PRODUCTS: "get_similar_products",
	LOAD_GET_SIMILAR_PRODUCTS: "load_get_similar_products",
	RES_GET_SIMILAR_PRODUCTS: "result_get_similar_products",
	ERR_GET_SIMILAR_PRODUCTS: "error_get_similar_products",
	HYDRATE: "HYDRATE",
};

// LOCAL API PRODUCTS
export const getApiSimilarProducts = (params) => `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-product/${params.id}/similar`;

// Function if failureSimilarProducts
export function failureSimilarProducts(error) {

	return {
		type: ActionsProducts.ERR_GET_SIMILAR_PRODUCTS,
		error,
	};

}

// Function if LoadSimilarProducts
export function loadSimilarProducts(params = {}) {

	return { type: ActionsProducts.GET_SIMILAR_PRODUCTS, ...params };

}

// Function if LoadSimilarProductsSuccess
export function loadSimilarProductsSuccess(data) {

	return {
		type: ActionsProducts.RES_GET_SIMILAR_PRODUCTS,
		data,
	};

}
