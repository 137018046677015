export const ActionsMyOrders = {
	GET_MY_ORDERS: "get_my_orders",
	LOAD_GET_MY_ORDERS: "load_get_my_orders",
	RES_GET_MY_ORDERS: "result_get_my_orders",
	ERR_GET_MY_ORDERS: "error_get_my_orders",
	HYDRATE: "HYDRATE",
};

export const API_MY_ORDERS = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-order/mine`;

//  Function if FailureMyOrders
export function failureMyOrders(error) {

	return { type: ActionsMyOrders.ERR_GET_MY_ORDERS, error };

}

//  Function if LoadMyOrders
export function loadMyOrders(params = {}) {

	return { type: ActionsMyOrders.GET_MY_ORDERS, ...params };

}

//  Function if LoadGetMyOrders
export function loadGetMyOrders(params = {}) {

	return { type: ActionsMyOrders.LOAD_GET_MY_ORDERS, ...params };

}

//  Function if LoadMyOrdersSuccess
export function loadMyOrdersSuccess(data) {

	return { type: ActionsMyOrders.RES_GET_MY_ORDERS, data };

}
