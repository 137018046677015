// Actions Promotion
export const ActionsAddress = {
	GET_ADDRESS: "get_address",
	ADD_ADDRESS: "add_address",
	EDIT_ADDRESS: "edit_address",
	DELETE_ADDRESS: "delete_address",
	LOAD_GET_ADDRESS: "load_get_address",
	RES_GET_ADDRESS: "result_get_address",
	ERR_GET_ADDRESS: "error_get_address",
	HYDRATE: "HYDRATE",
};

// LOCAL API PROMOTION
export const API_GET_ADDRESS = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-address`;

// Function if failureAddress
export function failureAddress(error) {

	return {
		type: ActionsAddress.ERR_GET_ADDRESS,
		error,
	};

}

// Function if loadAddress
export function loadAddress(params = {}) {

	return { type: ActionsAddress.GET_ADDRESS, ...params };

}

// Function if LoadGetAddres
export function loadGetAddress(params = {}) {

	return { type: ActionsAddress.LOAD_GET_ADDRESS, ...params };

}

// Function if loadAddressSuccess
export function loadAddressSuccess(data) {

	return {
		type: ActionsAddress.RES_GET_ADDRESS,
		data,
	};

}

// Function if addAddress
export function addAddress(params = {}) {

	return { type: ActionsAddress.ADD_ADDRESS, ...params };

}

// Function if editAddress
export function editAddress(params = {}) {

	return { type: ActionsAddress.EDIT_ADDRESS, ...params };

}

// Function if loadAddress
export function deleteAddress(params = {}) {

	return { type: ActionsAddress.DELETE_ADDRESS, ...params };

}
