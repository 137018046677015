import { call, put, takeEvery } from "redux-saga/effects";
import Env from "Consts/env";
import {
	ActionsCategoryDetail,
	API_CATEGORY,
	failureCategoryDetail,
	loadCategoryDetailSuccess,
} from "./actions";

// Function Fetch Category
function* loadDataCategoryDetail(params = {}) {

	try {

		const endpoint = `${API_CATEGORY}/${params.slug}`;
		const headers = { ...Env.HEADERS };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		if (typeof response.status !== "undefined" && response.status >= 200 && response.status < 300) {

			const self = yield response.json();

			yield put(loadCategoryDetailSuccess(self));

		} else {

			throw response;

		}

	} catch (err) {

		yield put(failureCategoryDetail(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsCategoryDetail.GET_CATEGORY_DETAIL, loadDataCategoryDetail);

}
