/* eslint-disable max-len */
import { call, put, takeEvery } from "redux-saga/effects";
import Env from "Consts/env";
import {
	ActionsHomepage,
	failureHomepage,
	loadHomepageSuccess,
	loadGetHomepage,
} from "./actions";

// Function Fetch Product
function* loadHomepage() {

	try {

		yield put(loadGetHomepage());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/homepage`;
		const headers = { ...Env.HEADERS };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		if (typeof response.status !== "undefined" && response.status >= 200 && response.status < 300) {

			const self = yield response.json();
			yield put(loadHomepageSuccess(self));

		} else {

			throw response;

		}

	} catch (err) {

		yield put(failureHomepage(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsHomepage.GET_HOMEPAGE, loadHomepage);

}
