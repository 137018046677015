// Actions Promotion
export const ActionsAppliedPromotion = {
	GET_APPLIED_PROMOTION: "GET_APPLIED_PROMOTION",
	LOAD_GET_APPLIED_PROMOTION: "load_GET_APPLIED_PROMOTION",
	RES_GET_APPLIED_PROMOTION: "result_GET_APPLIED_PROMOTION",
	ERR_GET_APPLIED_PROMOTION: "error_GET_APPLIED_PROMOTION",
	HYDRATE: "HYDRATE",
};

// LOCAL API PROMOTION
export const API_PROMOTION = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/coupons`;

// Function if FailureAppliedPromotion
export function failureAppliedPromotion(error) {

	return {
		type: ActionsAppliedPromotion.ERR_GET_APPLIED_PROMOTION,
		error,
	};

}

// Function if LoadAppliedPromotion
export function loadAppliedPromotion(params = {}) {

	return { type: ActionsAppliedPromotion.GET_APPLIED_PROMOTION, ...params };

}

// Function if LoadGetAppliedPromotion
export function loadGetAppliedPromotion(params = {}) {

	return { type: ActionsAppliedPromotion.LOAD_GET_APPLIED_PROMOTION, ...params };

}

// Function if LoadAppliedPromotionSuccess
export function loadAppliedPromotionSuccess(data) {

	return {
		type: ActionsAppliedPromotion.RES_GET_APPLIED_PROMOTION,
		data,
	};

}
