import { HYDRATE } from "next-redux-wrapper";
import { ActionsBanner } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeBanner(state = initialState, action) {

	switch (action.type) {

	case HYDRATE: {

		return { ...state, ...action.payload.storeBanner };

	}
	case ActionsBanner.LOAD_GET_BANNER:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsBanner.RES_GET_BANNER:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsBanner.ERR_GET_BANNER:
		return {
			...state, data: {}, status: "fetch_error", message: "Error...",
		};
	default: {

		return {
			...state,
		};

	}

	}

}

export default storeBanner;
