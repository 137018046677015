// Actions Promotion
export const ActionsCheckStock = {
	GET_CHECKSTOCK: "get_checkstock",
	LOAD_GET_CHECKSTOCK: "load_get_checkstock",
	RES_GET_CHECKSTOCK: "result_get_checkstock",
	ERR_GET_CHECKSTOCK: "error_get_checkstock",
	HYDRATE: "HYDRATE",
};

// LOCAL API PROMOTION
export const API_PRODUCT = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-product`;

// Function if failureCheckStock
export function failureCheckStock(error) {

	return {
		type: ActionsCheckStock.ERR_GET_CHECKSTOCK,
		error,
	};

}

// Function if loadCheckStock
export function loadCheckStock(params = {}) {

	return { type: ActionsCheckStock.GET_CHECKSTOCK, ...params };

}

// Function if LoadGetAddres
export function loadGetCheckStock(params = {}) {

	return { type: ActionsCheckStock.LOAD_GET_CHECKSTOCK, ...params };

}

// Function if loadCheckStockSuccess
export function loadCheckStockSuccess(data) {

	return {
		type: ActionsCheckStock.RES_GET_CHECKSTOCK,
		data,
	};

}
