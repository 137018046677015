// Actions Promotion
export const ActionsPromotionBrowse = {
	GET_PROMOTION_BROWSE: "get_promotion_browse",
	LOAD_GET_PROMOTION_BROWSE: "load_get_promotion_browse",
	RES_GET_PROMOTION_BROWSE: "result_get_promotion_browse",
	ERR_GET_PROMOTION_BROWSE: "error_get_promotion_browse",
	HYDRATE: "HYDRATE",
};

// LOCAL API PROMOTION
export const API_PROMOTION_BROWSE = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/promo/browse`;

// Function if FailurePromotionBrowse
export function failurePromotionBrowse(error) {

	return {
		type: ActionsPromotionBrowse.ERR_GET_PROMOTION_BROWSE,
		error,
	};

}

// Function if LoadPromotionBrowse
export function loadPromotionBrowse(params = {}) {

	return { type: ActionsPromotionBrowse.GET_PROMOTION_BROWSE, ...params };

}

// Function if LoadGetPromotionBrowse
export function loadGetPromotionBrowse(params = {}) {

	return { type: ActionsPromotionBrowse.LOAD_GET_PROMOTION_BROWSE, ...params };

}

// Function if LoadPromotionBrowseSuccess
export function loadPromotionBrowseSuccess(data) {

	return {
		type: ActionsPromotionBrowse.RES_GET_PROMOTION_BROWSE,
		data,
	};

}
