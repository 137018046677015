// Actions Category
export const ActionsCategoryDetail = {
	GET_CATEGORY_DETAIL: "get_category_detail",
	LOAD_GET_CATEGORY_DETAIL: "load_get_category_detail",
	RES_GET_CATEGORY_DETAIL: "result_get_category_detail",
	ERR_GET_CATEGORY_DETAIL: "error_get_category_detail",
	HYDRATE: "HYDRATE",
};

// LOCAL API CATEGORY
export const API_CATEGORY = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-category`;

// Function if FailureCategory
export function failureCategoryDetail(error) {

	return {
		type: ActionsCategoryDetail.ERR_GET_CATEGORY_DETAIL,
		error,
	};

}

// Function if LoadCategory
export function loadCategoryDetail(params = {}) {

	return { type: ActionsCategoryDetail.GET_CATEGORY_DETAIL, ...params };

}

// Function if LoadCategorySuccess
export function loadCategoryDetailSuccess(data) {

	return {
		type: ActionsCategoryDetail.RES_GET_CATEGORY_DETAIL,
		data,
	};

}
