// Actions Wishlist
export const ActionsWishlist = {
	GET_WISHLIST: "get_wishlist",
	LOAD_GET_WISHLIST: "load_get_wishlist",
	RES_GET_WISHLIST: "result_get_wishlist",
	ERR_GET_WISHLIST: "error_get_wishlist",
	HYDRATE: "HYDRATE",
};

// Function if FailureWishlist
export function failureWishlist(error) {

	return {
		type: ActionsWishlist.ERR_GET_WISHLIST,
		error,
	};

}

// Function if LoadWishlist
export function loadWishlist(params = {}) {

	return { type: ActionsWishlist.GET_WISHLIST, ...params };

}

// Function if LoadWishlist
export function loadGetWishlist(params = {}) {

	return { type: ActionsWishlist.LOAD_GET_WISHLIST, ...params };

}

// Function if LoadWishlistSuccess
export function loadGetWishlistSuccess(data) {

	return {
		type: ActionsWishlist.RES_GET_WISHLIST,
		data,
	};

}
