// Actions Promotion
export const ActionsDeletePromotion = {
	GET_DELETE_PROMOTION: "GET_DELETE_PROMOTION",
	LOAD_GET_DELETE_PROMOTION: "load_GET_DELETE_PROMOTION",
	RES_GET_DELETE_PROMOTION: "result_GET_DELETE_PROMOTION",
	ERR_GET_DELETE_PROMOTION: "error_GET_DELETE_PROMOTION",
	HYDRATE: "HYDRATE",
};

// LOCAL API PROMOTION
export const API_PROMOTION = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/coupons`;

// Function if FailureDeletePromotion
export function failureDeletePromotion(error) {

	return {
		type: ActionsDeletePromotion.ERR_GET_DELETE_PROMOTION,
		error,
	};

}

// Function if LoadDeletePromotion
export function loadDeletePromotion(params = {}) {

	return { type: ActionsDeletePromotion.GET_DELETE_PROMOTION, ...params };

}

// Function if LoadGetDeletePromotion
export function loadGetDeletePromotion(params = {}) {

	return { type: ActionsDeletePromotion.LOAD_GET_DELETE_PROMOTION, ...params };

}

// Function if LoadDeletePromotionSuccess
export function loadDeletePromotionSuccess(data) {

	return {
		type: ActionsDeletePromotion.RES_GET_DELETE_PROMOTION,
		data,
	};

}
