import { HYDRATE } from "next-redux-wrapper";
import { ActionsFlashSale } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeFlashSale(state = initialState, action) {

	switch (action.type) {

	case HYDRATE: {

		return { ...state, ...action.payload.storeFlashSale };

	}
	case ActionsFlashSale.LOAD_GET_FLASH_SALE:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsFlashSale.RES_GET_FLASH_SALE:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsFlashSale.ERR_GET_FLASH_SALE:
		return {
			...state, data: {}, status: "fetch_error", message: "Error...",
		};
	default: {

		return {
			...state,
		};

	}

	}

}

export default storeFlashSale;
