/* eslint-disable max-len */
import { call, put, takeEvery } from "redux-saga/effects";
import Env from "Consts/env";
import {
	ActionsProductDetail,
	API_PRODUCT_DETAIL,
	failureProductDetail,
	loadProductDetailSuccess,
} from "./actions";

// Function Fetch Product Detail
function* loadDataProductDetail(params = {}) {

	try {

		const endpoint = `${API_PRODUCT_DETAIL}/${params.payload.slug}`;
		const headers = { ...Env.HEADERS };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);

		if (typeof response.status !== "undefined" && response.status >= 200 && response.status < 300) {

			const self = yield response.json();

			yield put(loadProductDetailSuccess(self));

		} else {

			throw response;

		}

	} catch (err) {

		// console.log(err);
		yield put(failureProductDetail(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsProductDetail.GET_PRODUCT_DETAIL, loadDataProductDetail);

}
