/* eslint-disable max-len */
import {
	all, call, put, takeEvery,
} from "redux-saga/effects";
import Env from "Consts/env";
import { handle498OnClient } from "Helpers/is498";
import {
	ActionsMergeCart,
	failureMergeCart,
	loadMergeCartSuccess,
	loadGetMergeCart,
	API_MERGE_CART,
} from "./actions";

// Function Fetch MergeCart
function* loadDataMergeCart() {

	try {

		yield put(loadGetMergeCart());

		const endpoint = API_MERGE_CART;
		const headers = { ...Env.HEADERS };
		const method = "POST";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);

		const self = yield response.json();

		if (
			typeof response.status !== "undefined"
			&& response.status >= 200
			&& response.status <= 300
		) {

			yield all([
				put(handle498OnClient(self)),
				put(loadMergeCartSuccess(self)),
			]);

		} else {

			yield all([
				put(loadMergeCartSuccess(self)),
			]);

			throw response;

		}

	} catch (err) {

		yield put(failureMergeCart(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsMergeCart.GET_MERGE_CART, loadDataMergeCart);

}
