/* eslint-disable max-len */
import {
	all, call, put, takeEvery,
} from "redux-saga/effects";
import Env from "Consts/env";
import { handle498OnClient } from "Helpers/is498";
import {
	ActionsWishlist,
	failureWishlist,
	loadGetWishlistSuccess,
	loadGetWishlist,
} from "./actions";

// Function Fetch Wishlist
function* loadDataWishlist(params = {}) {

	try {

		yield put(loadGetWishlist());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-user/product/${params.userId}/wishlist`;
		const headers = {
			...Env.HEADERS,
			Authorization: params.Authorization,
			userId: params.userId,
		};
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		if (
			typeof response.status !== "undefined"
			&& response.status >= 200
			&& response.status < 300
		) {

			const self = yield response.json();

			yield all([
				put(handle498OnClient(self)),
				put(loadGetWishlistSuccess(self)),
			]);

		} else {

			throw response;

		}

	} catch (err) {

		yield put(failureWishlist(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsWishlist.GET_WISHLIST, loadDataWishlist);

}
