/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */

import PropTypes from "prop-types";
import ErrorBoundary from "Containers/error-boundary";
import { useSelector, useDispatch } from "react-redux";
import LoginPopup from "Components/loginpopup";
import { useEffect, useState } from "react";
import { loginOrSignUp } from "Helpers/is498";
import ClientCookies from "js-cookie";
import { loadSelf } from "@fdn/profile_store";
import Env from "Consts/env";
import { wrapper } from "../store";

function App({ Component, pageProps, err }) {

	const dispatch = useDispatch();
	const [isMobile, setIsMobile] = useState(false);
	const isLoginPopupShow = useSelector((state) => state.storeLoginPopup.data.isLoginPopupShow);
	useEffect(() => {

		const userAgent = navigator.userAgent || null;

		const isMobileCond = Boolean(
			userAgent.match(
				/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
			),
		);
		setIsMobile(isMobileCond);

	}, [setIsMobile]);

	if (typeof window !== "undefined") {

		useEffect(() => {

			const authorization = ClientCookies.get("token") || null;
			const usrname = ClientCookies.get("usrname") || null;
			const customUrl = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-profile/me`;

			if (authorization && usrname) {

				dispatch(
					loadSelf({
						payload: {
							api_env: process.env.NEXT_PUBLIC_NODE_ENV,
							env: Env.HEADERS,
							usrname,
							Authorization: authorization,
							is_server: true,
							customUrl,
						},
					}),
				);

			}

		}, [window.location.pathname]);

	}

	return (
		<ErrorBoundary>
			{isLoginPopupShow && (
				<LoginPopup
					onButtonClick={loginOrSignUp}
					show={isLoginPopupShow}
					isMobile={isMobile}
				/>
			)}
			<Component {...pageProps} err={err} />
		</ErrorBoundary>
	);

}

App.propTypes = {
	Component: PropTypes.func.isRequired,
	pageProps: PropTypes.objectOf(PropTypes.any).isRequired,
	err: PropTypes.objectOf(PropTypes.any),
};

App.defaultProps = {
	err: {},
};

// export default App
export default wrapper.withRedux(App);
