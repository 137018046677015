/* eslint-disable max-len */
import { call, put, takeEvery } from "redux-saga/effects";
import Env from "Consts/env";
import {
	ActionsFlashSale,
	failureFlashSale,
	loadFlashSaleSuccess,
	loadGetFlashSale,
} from "./actions";

// Function Fetch Product
function* loadDataBrands() {

	try {

		yield put(loadGetFlashSale());

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-flash-sale`;
		const headers = { ...Env.HEADERS };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		if (typeof response.status !== "undefined" && response.status >= 200 && response.status < 300) {

			const self = yield response.json();

			if (self.meta.code >= 200 && self.meta.code < 300) {

				yield put(loadFlashSaleSuccess(self));

			} else {

				throw self;

			}

		} else {

			throw response;

		}

	} catch (err) {

		yield put(failureFlashSale(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsFlashSale.GET_FLASH_SALE, loadDataBrands);

}
