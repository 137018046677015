import { HYDRATE } from "next-redux-wrapper";
import { ActionsCheckFlashSalePDP } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeCheckFlashSalePDP(state = initialState, action) {

	switch (action.type) {

	case HYDRATE: {

		return { ...state, ...action.payload.storeCheckFlashSalePDP };

	}
	case ActionsCheckFlashSalePDP.LOAD_GET_CHECKFLASHSALEPDP:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsCheckFlashSalePDP.RES_GET_CHECKFLASHSALEPDP:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsCheckFlashSalePDP.ERR_GET_CHECKFLASHSALEPDP:
		return {
			...state, data: {}, status: "fetch_error", message: "Error...",
		};
	default: {

		return {
			...state,
		};

	}

	}

}

export default storeCheckFlashSalePDP;
