import { call, put, takeEvery } from "redux-saga/effects";
import Env from "Consts/env";
import {
	ActionsCategory,
	API_CATEGORY,
	failureCategory,
	loadCategorySuccess,
} from "./actions";

// Function Fetch Category
function* loadDataCategory() {

	try {

		const endpoint = API_CATEGORY;
		const headers = { ...Env.HEADERS };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		if (typeof response.status !== "undefined" && response.status >= 200 && response.status < 300) {

			const self = yield response.json();

			yield put(loadCategorySuccess(self));

		} else {

			throw response;

		}

	} catch (err) {

		yield put(failureCategory(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsCategory.GET_CATEGORY, loadDataCategory);

}
