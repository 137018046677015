import { call, put, takeEvery } from "redux-saga/effects";
import Env from "Consts/env";
import {
	ActionsCategoryDesktop,
	failureCategoryDesktop,
	loadCategoryDesktopSuccess,
} from "./actions";

// Function Fetch Category Desktop
function* loadDataCategoryDesktop() {

	try {

		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-category/2`;

		const headers = { ...Env.HEADERS };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		if (typeof response.status !== "undefined" && response.status >= 200 && response.status < 300) {

			const self = yield response.json();

			yield put(loadCategoryDesktopSuccess(self));

		} else {

			throw response;

		}

	} catch (err) {

		yield put(failureCategoryDesktop(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsCategoryDesktop.GET_CATEGORY_DESKTOP, loadDataCategoryDesktop);

}
