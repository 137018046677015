// Actions CartTotal
export const ActionsCartTotal = {
	GET_CART_TOTAL: "get_cart_total",
	LOAD_GET_CART_TOTAL: "load_get_cart_total",
	RES_GET_CART_TOTAL: "result_get_cart_total",
	ERR_GET_CART_TOTAL: "error_get_cart_total",
	HYDRATE: "HYDRATE",
};

// LOCAL API CART_TOTAL
export const API_CART_TOTAL = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/total`;

// Function if FailureCartTotal
export function failureCartTotal(error) {

	return {
		type: ActionsCartTotal.ERR_GET_CART_TOTAL,
		error,
	};

}

// Function if LoadCartTotal
export function loadCartTotal(params = {}) {

	return { type: ActionsCartTotal.GET_CART_TOTAL, ...params };

}

// Function if LoadGetCartTotal
export function loadGetCartTotal(params = {}) {

	return { type: ActionsCartTotal.LOAD_GET_CART_TOTAL, ...params };

}

// Function if LoadCartTotalSuccess
export function loadCartTotalSuccess(data) {

	return {
		type: ActionsCartTotal.RES_GET_CART_TOTAL,
		data,
	};

}
