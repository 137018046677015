// Actions Products
export const ActionsProducts = {
	GET_PRODUCTS: "get_products",
	LOAD_GET_PRODUCTS: "load_get_products",
	RES_GET_PRODUCTS: "result_get_products",
	ERR_GET_PRODUCTS: "error_get_products",
	HYDRATE: "HYDRATE",
};

// LOCAL API PRODUCTS
export const API_PRODUCTS = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-product`;

// Function if FailureProducts
export function failureProducts(error) {

	return {
		type: ActionsProducts.ERR_GET_PRODUCTS,
		error,
	};

}

// Function if LoadProducts
export function loadProducts(params = {}) {

	return { type: ActionsProducts.GET_PRODUCTS, ...params };

}

// Function if LoadProductsSuccess
export function loadProductsSuccess(data) {

	return {
		type: ActionsProducts.RES_GET_PRODUCTS,
		data,
	};

}
