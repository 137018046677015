import React from "react";
import PropTypes from "prop-types";
import Button from "@fdn/button";

function Desktop({
	title, desc, buttonText, show, zIndex, minWidth, onHideCallback, onButtonClickCallback,
}) {

	return (
		<>

			<div onClick={onHideCallback} aria-hidden="true" className={`LoginPopupDesktop__overlay${show ? " show" : ""}`} />
			<div className="LoginPopupDesktop">
				<h4>{title}</h4>
				<p>{desc}</p>
				<div className="LoginPopupDesktop__footer">
					<Button on_click={onButtonClickCallback} id_element="LoginPopupDesktop_Btn" variant="primary-bs" font_weight="600" border_radius="2px" padding="8px 16px">{buttonText}</Button>
					<style>
						{`
						.fdn-button-primary-bs-LoginPopupDesktop_Btn {
							letter-spacing: 0.56px;
						}
						`}
					</style>
				</div>
			</div>
			<style jsx>
				{`

				.LoginPopupDesktop__overlay {
					position: fixed;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background: #000;
					z-index: ${Number(zIndex) - 1};
					opacity: 0;
					visibility: hidden;
					transition: all .4s ease;
				}
				.show {
					opacity: 0.5;
					visibility: visible;
				}
					.LoginPopupDesktop {
            min-width: ${minWidth};
					  position: fixed;
						padding: 24px;
            background-color: #fff;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
					  z-index: ${Number(zIndex)};
					}
					.LoginPopupDesktop h4 {
						/* Heading/H4 */
						font-size: 20px;
						font-style: normal;
						font-weight: 600;
						line-height: 28px; /* 140% */
						letter-spacing: 0.8px;
            margin: 0;
					}
					.LoginPopupDesktop p {
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 22px; /* 157.143% */
						letter-spacing: 0.56px;
            margin: 16px 0;
					}
          .LoginPopupDesktop__footer {
            display: flex;
            justify-content: end;
          }
				`}
			</style>
		</>
	);

}

Desktop.propTypes = {
	title: PropTypes.string.isRequired,
	desc: PropTypes.string.isRequired,
	buttonText: PropTypes.string.isRequired,
	show: PropTypes.bool.isRequired,
	onHideCallback: PropTypes.func.isRequired,
	onButtonClickCallback: PropTypes.func.isRequired,
	minWidth: PropTypes.string.isRequired,
	zIndex: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]).isRequired,
};

export default Desktop;
