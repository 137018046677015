import { HYDRATE } from "next-redux-wrapper";
import { ActionsAddress } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeAddress(state = initialState, action) {

	switch (action.type) {

	case HYDRATE: {

		return { ...state, ...action.payload.storeAddress };

	}
	case ActionsAddress.LOAD_GET_ADDRESS:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsAddress.RES_GET_ADDRESS:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsAddress.ERR_GET_ADDRESS:
		return {
			...state, data: {}, status: "fetch_error", message: "Error...",
		};
	default: {

		return {
			...state,
		};

	}

	}

}

export default storeAddress;
