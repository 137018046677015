/* eslint-disable max-len */
import {
	all, call, put, takeEvery,
} from "redux-saga/effects";
import Env from "Consts/env";
import { handle498OnClient } from "Helpers/is498";
import {
	ActionsDeletePromotion,
	failureDeletePromotion,
	loadDeletePromotionSuccess,
	loadGetDeletePromotion,
	API_PROMOTION,
} from "./actions";

// Function Fetch DeletePromotion
function* loadDataDeletePromotion(params = {}) {

	try {

		yield put(loadGetDeletePromotion());

		const endpoint = API_PROMOTION;
		const headers = { ...Env.HEADERS, cookie: params.cookie };
		const method = "DELETE";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		if (
			typeof response.status !== "undefined"
			&& response.status >= 200
			&& response.status < 300
		) {

			const self = yield response.json();

			yield all([
				put(handle498OnClient(self)),
				put(loadDeletePromotionSuccess(self)),
			]);

		} else {

			throw response;

		}

	} catch (err) {

		yield put(failureDeletePromotion(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(
		ActionsDeletePromotion.GET_DELETE_PROMOTION,
		loadDataDeletePromotion,
	);

}
