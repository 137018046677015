/* eslint-disable max-len */
import { call, put, takeEvery } from "redux-saga/effects";
import Env from "Consts/env";
import {
	ActionsPromotionDetail,
	API_PROMOTION_DETAIL,
	failurePromotionDetail,
	loadPromotionDetailSuccess,
} from "./actions";

// Function Fetch Promotion Detail
function* loadDataPromotionDetail(params = {}) {

	try {

		const endpoint = `${API_PROMOTION_DETAIL}/${params.payload.id}`;
		const headers = { ...Env.HEADERS };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);

		if (typeof response.status !== "undefined" && response.status >= 200 && response.status < 300) {

			const self = yield response.json();

			yield put(loadPromotionDetailSuccess(self));

		} else {

			throw response;

		}

	} catch (err) {

		// console.log(err);
		yield put(failurePromotionDetail(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsPromotionDetail.GET_PROMOTION_DETAIL, loadDataPromotionDetail);

}
