import { HYDRATE } from "next-redux-wrapper";
import { ActionsCategory } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeCategory(state = initialState, action) {

	switch (action.type) {

	case HYDRATE: {

		return { ...state, ...action.payload.storeCategory };

	}
	case ActionsCategory.LOAD_GET_CATEGORY:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsCategory.RES_GET_CATEGORY:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsCategory.ERR_GET_CATEGORY:
		return {
			...state, data: {}, status: "fetch_error", message: "Error...",
		};
	default: {

		return {
			...state,
		};

	}

	}

}

export default storeCategory;
