/* eslint-disable max-len */
import {
	all, call, put, takeEvery,
} from "redux-saga/effects";
import Env from "Consts/env";
import { handle498OnClient } from "Helpers/is498";
import {
	ActionsCartBadgeCount,
	API_CART_TOTAL,
	failureCartBadgeCount,
	loadCartBadgeCountSuccess,
} from "./actions";

// Function Fetch Product
function* loadDataCartBadgeCount() {

	try {

		const endpoint = API_CART_TOTAL;
		const headers = { ...Env.HEADERS };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		if (
			typeof response.status !== "undefined"
			&& response.status >= 200
			&& response.status < 300
		) {

			const self = yield response.json();
			yield all([
				put(handle498OnClient(self)),
				put(loadCartBadgeCountSuccess(self)),
			]);

		} else {

			throw response;

		}

	} catch (err) {

		yield put(failureCartBadgeCount(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(
		ActionsCartBadgeCount.GET_CART_BADGE_COUNT,
		loadDataCartBadgeCount,
	);

}
