// Actions MergeCart
export const ActionsMergeCart = {
	GET_MERGE_CART: "get_merge_cart",
	LOAD_GET_MERGE_CART: "load_get_merge_cart",
	RES_GET_MERGE_CART: "result_get_merge_cart",
	ERR_GET_MERGE_CART: "error_get_merge_cart",
	HYDRATE: "HYDRATE",
};

// LOCAL API MERGE_CART
export const API_MERGE_CART = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/merge`;

// Function if FailureMergeCart
export function failureMergeCart(error) {

	return {
		type: ActionsMergeCart.ERR_GET_MERGE_CART,
		error,
	};

}

// Function if LoadMergeCart
export function loadMergeCart(params = {}) {

	return { type: ActionsMergeCart.GET_MERGE_CART, ...params };

}

// Function if LoadGetMergeCart
export function loadGetMergeCart(params = {}) {

	return { type: ActionsMergeCart.LOAD_GET_MERGE_CART, ...params };

}

// Function if LoadMergeCartSuccess
export function loadMergeCartSuccess(data) {

	return {
		type: ActionsMergeCart.RES_GET_MERGE_CART,
		data,
	};

}
