import React from "react";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { connect } from "react-redux";
import ClientCookies from "js-cookie";
import vars from "Consts/vars";

const Layout = dynamic(() => import("Containers/layout"),
	{
		ssr: false,

	});

class ErrorBoundary extends React.Component {

	constructor(props) {

		super(props);

		// Define a state variable to track whether is an error or not
		this.state = { hasError: false };

	}

	static getDerivedStateFromError() {

		// Update state so the next render will show the fallback UI

		return { hasError: true };

	}

	componentDidCatch(error, errorInfo) {

		// You can use your own error logging service here
		console.log({ error, errorInfo });

	}

	render() {

		const { hasError } = this.state;
		// eslint-disable-next-line react/prop-types
		const { children, storeSelf = null } = this.props;
		const authorization = ClientCookies.get("token") || null;
		const usrname = ClientCookies.get("usrname") || null;

		const HandleToHomepage = () => {

			window.location.href = "/";

		};

		const passStoreSelf = () => {

			if (authorization && usrname) {

				return storeSelf;

			}
			return { status: vars.FETCH_RESULT };

		};

		// Check if the error is thrown
		if (hasError) {

			// You can render any custom fallback UI
			return (
				<>
					<Layout
						home
						titleNavbar="Not Found"
						metaTitle="Not Found"
						typeNavbarSecondary="type-1"
						self={passStoreSelf()}
						isAdaptiveLoad
					>
						<section className="err-section">
							<div className="err-container">
								<div>
									<img src="/images/not_found.png" alt="no_promotion_available" />
								</div>
								<div className="err-info">
									<h1 className="title">SOMETHING WENT WRONG</h1>
									<p className="subtitle">
										Uh oh!
										Sorry, something went wrong with the page.

									</p>
									<div className="err-to-homepage" onClick={HandleToHomepage} aria-hidden="true">
										<h4>Back to homepage</h4>
									</div>
								</div>
							</div>
						</section>
					</Layout>
					<style>
						{`
							.err-image {
								width: 400px;
							}
							.err-section {
								display: flex;
								justify-content: center;
								align-items: center;
								text-align: center;
								margin: 140px 0;
							}
							.err-container{
								display: grid;
								grid-template-columns: auto auto;
								gap: 80px;
							}
							.err-info{
								width: 370px;
							}
							.navbar-secondary-1-mobile.show {
								height: 62px;
								opacity: 1;
								visibility: visible;
								width: 100%;
							}
							.title{
								
								font-size: 38px;
								font-weight: 600;
								line-height: 46px;
								letter-spacing: 0.04em;
								text-align: left;
							}
							.subtitle{
								
								font-size: 20px;
								font-weight: 400;
								line-height: 30px;
								letter-spacing: 0.04em;
								text-align: left;
							}
							.err-to-homepage{
								
								font-size: 20px;
								font-weight: 600;
								line-height: 28px;
								letter-spacing: 0.04em;
								text-align: left;
								cursor: pointer;
							}
							@media screen and (max-width: 500px) {
								.top-menu .wrapper,
								.search-input-wrapper,
								.header-right-menu {
									display: none;
								}
								.err-section{
									margin: 20px;
									display: flex;
									justify-content: left;
								}
								.err-container{
									display: grid;
									grid-template-columns: auto;
									gap: 10px;
									width: 200px;
								}
								img {
									width: 80%;
								}
								.bs-footer-desktop .grid {
									margin: 1em 0;
									display: grid;
									grid-template-columns: repeat(1, 1fr);
									grid-gap: 10px;
									counter-reset: div;
								}
							}
						`}
					</style>
				</>
			);

		}

		// Return children components in case of no error

		return children;

	}

}

ErrorBoundary.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(ErrorBoundary);
