import { HYDRATE } from "next-redux-wrapper";
import { ActionsCategoryDesktop } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeCategoryDesktop(state = initialState, action) {

	switch (action.type) {

	case HYDRATE: {

		return { ...state, ...action.payload.storeCategoryDesktop };

	}
	case ActionsCategoryDesktop.LOAD_GET_CATEGORY_DESKTOP:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsCategoryDesktop.RES_GET_CATEGORY_DESKTOP:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsCategoryDesktop.ERR_GET_CATEGORY_DESKTOP:
		return {
			...state, data: {}, status: "fetch_error", message: "Error...",
		};
	default: {

		return {
			...state,
		};

	}

	}

}

export default storeCategoryDesktop;
