import { combineReducers } from "redux";
import { storeSelf } from "@fdn/profile_store";
import storeCategory from "../category_store/lib/store";
import storeCategoryDesktop from "../category_desktop_store/lib/store";
import storeProducts from "../products_store/lib/store";
import storeBrands from "../brands_store/lib/store";
import storeProductDetail from "../product_detail_store/lib/store";
import storeFlashSale from "../flash_sale_store/lib/store";
import storePromotions from "../promotion_store/lib/store";
import storePromotionDetail from "../promotion_detail_store/lib/store";
import storePromotionsBrowse from "../promotion_browse_store/lib/store";
import storeBanner from "../banner_store/lib/store";
import storeAddress from "../address_store/lib/store";
import storeWishlist from "../wishlist_store/lib/store";
import storeSimilarProduct from "../similar_product_store/lib/store";
import storeCartBadgeCount from "../cart_badge_count_store/lib/store";
import storeCheckStock from "../checkstock_store/lib/store";
import storeCategoryDetail from "../category_detail_store/lib/store";
import storeAppliedPromotion from "../applied_promotion_store/lib/store";
import storeHomepage from "../homepage_store/lib/store";
import storeDeletePromotion from "../delete_promotion_store/lib/store";
import storeMergeCart from "../merge_cart_store/lib/store";
import storeMyOrders from "../my_orders_store/lib/store";
import storeCartTotals from "../cart_total_store/lib/store";
import storeCheckFlashSalePDP from "../checkflashsalepdp_store/lib/store";
import storeLoginPopup from "../login_popup_store/lib/store";

const rootReducer = combineReducers({
	storeProductDetail,
	storeSelf,
	storeCategory,
	storeCategoryDesktop,
	storeProducts,
	storeBrands,
	storeFlashSale,
	storePromotions,
	storePromotionDetail,
	storePromotionsBrowse,
	storeBanner,
	storeAddress,
	storeWishlist,
	storeSimilarProduct,
	storeCartBadgeCount,
	storeCheckStock,
	storeCategoryDetail,
	storeAppliedPromotion,
	storeHomepage,
	storeDeletePromotion,
	storeMergeCart,
	storeMyOrders,
	storeCartTotals,
	storeCheckFlashSalePDP,
	storeLoginPopup,
});

export default rootReducer;
