// Actions CategoryDesktop
export const ActionsCategoryDesktop = {
	GET_CATEGORY_DESKTOP: "get_category_desktop",
	LOAD_GET_CATEGORY_DESKTOP: "load_get_category_desktop",
	RES_GET_CATEGORY_DESKTOP: "result_get_category_desktop",
	ERR_GET_CATEGORY_DESKTOP: "error_get_category_desktop",
	HYDRATE: "HYDRATE",
};

// Function if FailureCategoryDesktop
export function failureCategoryDesktop(error) {

	return {
		type: ActionsCategoryDesktop.ERR_GET_CATEGORY_DESKTOP,
		error,
	};

}

// Function if LoadCategoryDesktop
export function loadCategoryDesktop(params = {}) {

	return { type: ActionsCategoryDesktop.GET_CATEGORY_DESKTOP, ...params };

}

// Function if LoadCategoryDesktopSuccess
export function loadCategoryDesktopSuccess(data) {

	return {
		type: ActionsCategoryDesktop.RES_GET_CATEGORY_DESKTOP,
		data,
	};

}
