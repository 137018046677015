import { HYDRATE } from "next-redux-wrapper";
import { ActionsProducts } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeProducts(state = initialState, action) {

	switch (action.type) {

	case HYDRATE: {

		return { ...state, ...action.payload.storeProducts };

	}
	case ActionsProducts.LOAD_GET_SIMILAR_PRODUCTS:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsProducts.RES_GET_SIMILAR_PRODUCTS:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsProducts.ERR_GET_SIMILAR_PRODUCTS:
		return {
			...state, data: {}, status: "fetch_error", message: "Error...",
		};
	default: {

		return {
			...state,
		};

	}

	}

}

export default storeProducts;
