import Env from "Consts/env";
import {
	call, takeEvery, put, all,
} from "redux-saga/effects";
import { handle498OnClient } from "Helpers/is498";
import {
	failureMyOrders,
	ActionsMyOrders,
	API_MY_ORDERS,
	loadMyOrdersSuccess,
	loadGetMyOrders,
} from "./actions";

function* loadDataMyOrders(params = {}) {

	try {

		yield put(loadGetMyOrders());
		const endpoint = `${API_MY_ORDERS}?limit=${params.limit || 5}&page=${
			params.page || 1
		}&status=${params.status || ""}&searchTerm=${params.searchTerm || ""}`;
		const headers = { ...Env.HEADERS };
		const method = "GET";
		const options = { headers, method };
		const response = yield call(fetch, endpoint, options);
		if (
			typeof response.status !== "undefined"
			&& response.status >= 200
			&& response.status < 300
		) {

			const self = yield response.json();

			yield all([
				put(handle498OnClient(self)),
				put(loadMyOrdersSuccess(self)),
			]);

		} else {

			throw response;

		}

	} catch (error) {

		yield put(failureMyOrders(error));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsMyOrders.GET_MY_ORDERS, loadDataMyOrders);

}
