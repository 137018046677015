// Actions FlashSale
export const ActionsFlashSale = {
	GET_FLASH_SALE: "get_flash_sale",
	LOAD_GET_FLASH_SALE: "load_get_flash_sale",
	RES_GET_FLASH_SALE: "result_get_flash_sale",
	ERR_GET_FLASH_SALE: "error_get_flash_sale",
	HYDRATE: "HYDRATE",
};

// Function if FailureFlashSale
export function failureFlashSale(error) {

	return {
		type: ActionsFlashSale.ERR_GET_FLASH_SALE,
		error,
	};

}

// Function if LoadFlashSale
export function loadFlashSale(params = {}) {

	return { type: ActionsFlashSale.GET_FLASH_SALE, ...params };

}

// Function if LoadGetFlashSale
export function loadGetFlashSale(params = {}) {

	return { type: ActionsFlashSale.LOAD_GET_FLASH_SALE, ...params };

}

// Function if LoadFlashSaleSuccess
export function loadFlashSaleSuccess(data) {

	return {
		type: ActionsFlashSale.RES_GET_FLASH_SALE,
		data,
	};

}
