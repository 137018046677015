// Actions Category
export const ActionsCategory = {
	GET_CATEGORY: "get_category",
	LOAD_GET_CATEGORY: "load_get_category",
	RES_GET_CATEGORY: "result_get_category",
	ERR_GET_CATEGORY: "error_get_category",
	HYDRATE: "HYDRATE",
};

// LOCAL API CATEGORY
export const API_CATEGORY = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-category/3`;

// Function if FailureCategory
export function failureCategory(error) {

	return {
		type: ActionsCategory.ERR_GET_CATEGORY,
		error,
	};

}

// Function if LoadCategory
export function loadCategory(params = {}) {

	return { type: ActionsCategory.GET_CATEGORY, ...params };

}

// Function if LoadCategorySuccess
export function loadCategorySuccess(data) {

	return {
		type: ActionsCategory.RES_GET_CATEGORY,
		data,
	};

}
