import React from "react";
import PropTypes from "prop-types";
import Button from "@fdn/button";

function Mobile({
	title, desc, buttonText, show, zIndex, onHideCallback, onButtonClickCallback,
}) {

	return (
		<>

			<div onClick={onHideCallback} aria-hidden="true" className={`LoginPopupMobile__overlay${show ? " show" : ""}`} />
			<div className="LoginPopupMobile">
				<h4>{title}</h4>
				<p>{desc}</p>
				<div className="LoginPopupMobile__footer">
					<Button on_click={onButtonClickCallback} id_element="LoginPopupMobile__Btn498" block variant="primary-bs" font_weight="600" border_radius="2px" padding="8px 16px">{buttonText}</Button>
					<style>
						{`
						.fdn-button-primary-bs-LoginPopupMobile__Btn498 {
							letter-spacing: 0.56px;
						}
						`}
					</style>
				</div>
			</div>
			<style jsx>
				{`

				.LoginPopupMobile__overlay {
					position: fixed;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background: #000;
					z-index: ${Number(zIndex) - 1};
					opacity: 0;
					visibility: hidden;
					transition: all .4s ease;
				}
				.show {
					opacity: 0.5;
					visibility: visible;
				}
					.LoginPopupMobile {
					  position: fixed;
            background-color: #fff;
					  z-index: ${Number(zIndex)};
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            visibility: visible;
            transition: all .3s ease;
            margin: auto 32px;
            height: max-content;
					}
					.LoginPopupMobile h4 {
            text-align: center;
						font-size: 20px;
						font-style: normal;
						font-weight: 600;
						line-height: 28px; /* 140% */
						text-transform: capitalize;
            margin: 16px 24px;
					}
					.LoginPopupMobile p {
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px; /* 150% */
            margin: 8px 20px;
					}
          .LoginPopupMobile__footer {
            display: flex;
            margin: 16px 24px;
          }
				`}
			</style>
		</>
	);

}

Mobile.propTypes = {
	title: PropTypes.string.isRequired,
	desc: PropTypes.string.isRequired,
	buttonText: PropTypes.string.isRequired,
	show: PropTypes.bool.isRequired,
	onHideCallback: PropTypes.func.isRequired,
	onButtonClickCallback: PropTypes.func.isRequired,
	zIndex: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]).isRequired,
};

export default Mobile;
