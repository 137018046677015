// Actions Promotion
export const ActionsPromotion = {
	GET_PROMOTION: "get_promotion",
	LOAD_GET_PROMOTION: "load_get_promotion",
	RES_GET_PROMOTION: "result_get_promotion",
	ERR_GET_PROMOTION: "error_get_promotion",
	HYDRATE: "HYDRATE",
};

// LOCAL API PROMOTION
export const API_PROMOTION = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/promo`;

// Function if FailurePromotion
export function failurePromotion(error) {

	return {
		type: ActionsPromotion.ERR_GET_PROMOTION,
		error,
	};

}

// Function if LoadPromotion
export function loadPromotion(params = {}) {

	return { type: ActionsPromotion.GET_PROMOTION, ...params };

}

// Function if LoadGetPromotion
export function loadGetPromotion(params = {}) {

	return { type: ActionsPromotion.LOAD_GET_PROMOTION, ...params };

}

// Function if LoadPromotionSuccess
export function loadPromotionSuccess(data) {

	return {
		type: ActionsPromotion.RES_GET_PROMOTION,
		data,
	};

}
