// Actions CartBadgeCount
export const ActionsCartBadgeCount = {
	GET_CART_BADGE_COUNT: "get_cart_badge_count",
	LOAD_GET_CART_BADGE_COUNT: "load_get_cart_badge_count",
	RES_GET_CART_BADGE_COUNT: "result_get_cart_badge_count",
	ERR_GET_CART_BADGE_COUNT: "error_get_cart_badge_count",
	HYDRATE: "HYDRATE",
};

// LOCAL API PRODUCTS
export const API_CART_TOTAL = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/total/badge-count`;

// Function if FailureCartBadgeCount
export function failureCartBadgeCount(error) {

	return {
		type: ActionsCartBadgeCount.ERR_GET_CART_BADGE_COUNT,
		error,
	};

}

// Function if LoadCartBadgeCount
export function loadCartBadgeCount(params = {}) {

	return { type: ActionsCartBadgeCount.GET_CART_BADGE_COUNT, ...params };

}

// Function if LoadCartBadgeCountSuccess
export function loadCartBadgeCountSuccess(data) {

	const res = Number.isInteger(data.data) ? data.data : 0;
	return {
		type: ActionsCartBadgeCount.RES_GET_CART_BADGE_COUNT,
		data: res,
	};

}
