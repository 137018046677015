import {
	all, call, put, takeEvery,
} from "redux-saga/effects";
import Env from "Consts/env";
import { handle498OnClient } from "Helpers/is498";
import {
	ActionsPromotionBrowse,
	failurePromotionBrowse,
	loadPromotionBrowseSuccess,
	loadGetPromotionBrowse,
	API_PROMOTION_BROWSE,
} from "./actions";

// Function Fetch Promotion
function* loadDataPromotionBrowse(params) {

	try {

		yield put(loadGetPromotionBrowse());

		const endpoint = API_PROMOTION_BROWSE;

		const headers = {
			...Env.HEADERS,
			quoteId: params.quoteId,
			authorization: params.authorization,
		};
		const method = "GET";
		const options = { headers, method, params };

		const response = yield call(fetch, endpoint, options);
		if (
			typeof response.status !== "undefined"
			&& response.status >= 200
			&& response.status < 300
		) {

			const self = yield response.json();

			yield all([
				put(handle498OnClient(self)),
				put(loadPromotionBrowseSuccess(self)),
			]);

		} else {

			throw response;

		}

	} catch (err) {

		console.log("error", err);

		yield put(failurePromotionBrowse(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(
		ActionsPromotionBrowse.GET_PROMOTION_BROWSE,
		loadDataPromotionBrowse,
	);

}
