export default {

	// Headers
	HEADERS: {
		key: typeof process.env.KEY_API === "undefined" ? process.env.NEXT_PUBLIC_KEY_API_CLIENT : process.env.KEY_API,
		version: "1.5",
		device: "3",
		"content-type": "application/json",
		"api-key": typeof process.env.FDS_SERVER_API_KEY === "undefined" ? process.env.NEXT_PUBLIC_FDS_CLIENT_API_KEY : process.env.FDS_SERVER_API_KEY,
	},
	// Api timeout
	REQUEST_TIMEOUT: 50000,
};
