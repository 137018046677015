// Actions Product Detail
export const ActionsPromotionDetail = {
	GET_PROMOTION_DETAIL: "get_promotion_detail",
	LOAD_GET_PROMOTION_DETAIL: "load_get_promotion_detail",
	RES_GET_PROMOTION_DETAIL: "result_get_promotion_detail",
	ERR_GET_PROMOTION_DETAIL: "error_get_promotion_detail",
	HYDRATE: "HYDRATE",
};

// LOCAL API PRODUCT DETAIL
export const API_PROMOTION_DETAIL = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/promo`;

// Function if FailurePromotionDetail
export function failurePromotionDetail(error) {

	return {
		type: ActionsPromotionDetail.GET_PROMOTION_DETAIL,
		error,
	};

}

// Function if LoadPromotionDetail
export function loadPromotionDetail(params = {}) {

	return { type: ActionsPromotionDetail.GET_PROMOTION_DETAIL, ...params };

}

// Function if LoadPromotionDetailSuccess
export function loadPromotionDetailSuccess(data) {

	return {
		type: ActionsPromotionDetail.RES_GET_PROMOTION_DETAIL,
		data,
	};

}
