import { HYDRATE } from "next-redux-wrapper";
import { ActionsProductDetail } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeProductDetail(state = initialState, action) {

	switch (action.type) {

	case HYDRATE: {

		return { ...state, ...action.payload.storeProductDetail };

	}
	case ActionsProductDetail.LOAD_GET_PRODUCT_DETAIL:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsProductDetail.RES_GET_PRODUCT_DETAIL:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsProductDetail.ERR_GET_PRODUCT_DETAIL:
		return {
			...state, data: {}, status: "fetch_error", message: "Error...",
		};
	default: {

		return {
			...state,
		};

	}

	}

}

export default storeProductDetail;
