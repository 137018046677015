/* eslint-disable max-len */
import {
	call, put, takeEvery,
} from "redux-saga/effects";
import Env from "Consts/env";
import {
	ActionsCheckFlashSalePDP,
	failureCheckFlashSalePDP,
	loadCheckFlashSalePDPSuccess,
	loadGetCheckFlashSalePDP,
	API_PRODUCT,
} from "./actions";

// Function Fetch ActionsCheckFlashSalePDP
function* loadDataActionsCheckFlashSalePDP(params = {}) {

	try {

		yield put(loadGetCheckFlashSalePDP());
		// const endpoint = `${API_GET_CHECKFLASHSALEPDP}/?stockId=${params.payload.stockId}&sku=${params.payload.sku}&tokenMagento=${params.payload.tokenMagento}`;
		const endpoint = `${API_PRODUCT}/${params.product_id}/variants/flashsale`;
		const headers = { ...Env.HEADERS };
		const method = "GET";
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		if (typeof response.status !== "undefined" && response.status >= 200 && response.status < 300) {

			const self = yield response.json();
			yield put(loadCheckFlashSalePDPSuccess(self));

		} else {

			throw response;

		}

	} catch (err) {

		yield put(failureCheckFlashSalePDP(err));

	}

}

export default function* rootSaga() {

	yield takeEvery(ActionsCheckFlashSalePDP.GET_CHECKFLASHSALEPDP, loadDataActionsCheckFlashSalePDP);
	// yield all([takeEvery(ActionsCheckStock.ADD_ADDRESS, loadAddDataAddress)]);
	// yield all([takeEvery(ActionsCheckStock.DELETE_ADDRESS, loadDeleteDataAddress)]);
	// yield all([takeEvery(ActionsCheckStock.EDIT_ADDRESS, loadEditDataAddress)]);

}
