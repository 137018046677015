import { loadLoginPopupSuccess } from "@/Store/login_popup_store/lib/actions";

// eslint-disable-next-line import/prefer-default-export
export const is498 = (code) => code === 498;

export const loginOrSignUp = () => {

	const pageReferer = window.location.href;
	window.location.href = `${process.env.NEXT_PUBLIC_SSO_DEV_DOMAIN || process.env.NEXT_PUBLIC_SSO_DOMAIN}/?url=${pageReferer}`;

};

export const handle498OnClient = (res) => {

	// const allOptions = options;
	// allOptions.isSaga = options.isSaga || allOptions.allOptions || false;
	// allOptions.dispatch = options.dispatch || allOptions.dispatch;
	const setState = { isLoginPopupShow: false };
	if (typeof window !== "undefined" && res && ((res?.meta
  && res?.meta.code
  && is498(res?.meta.code))
	)
	) {

		setState.isLoginPopupShow = true;
		return loadLoginPopupSuccess(setState);

		// loginOrSignUp();

	}

	return loadLoginPopupSuccess(setState);

};
