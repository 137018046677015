import { HYDRATE } from "next-redux-wrapper";
import { ActionsHomepage } from "./actions";

const initialState = { data: {}, status: "fetch_init", message: "" };

function storeHomepage(state = initialState, action) {

	switch (action.type) {

	case HYDRATE: {

		return { ...state, ...action.payload.storeHomepage };

	}
	case ActionsHomepage.LOAD_GET_HOMEPAGE:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsHomepage.RES_GET_HOMEPAGE:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsHomepage.ERR_GET_HOMEPAGE:
		return {
			...state, data: {}, status: "fetch_error", message: "Error...",
		};
	default: {

		return {
			...state,
		};

	}

	}

}

export default storeHomepage;
