import { fork } from "redux-saga/effects";
import { dispatchSelf } from "@fdn/profile_store";
import dispatchCategory from "../category_store/lib/fetch";
import dispatchCategoryDesktop from "../category_desktop_store/lib/fetch";
import dispatchProducts from "../products_store/lib/fetch";
import dispatchBrands from "../brands_store/lib/fetch";
import dispatchProductDetail from "../product_detail_store/lib/fetch";
import dispatchFlashSale from "../flash_sale_store/lib/fetch";
import dispatchPromotion from "../promotion_store/lib/fetch";
import dispatchPromotionDetail from "../promotion_detail_store/lib/fetch";
import dispatchPromotionBrowse from "../promotion_browse_store/lib/fetch";
import dispatchBanner from "../banner_store/lib/fetch";
import dispatchAddress from "../address_store/lib/fetch";
import dispatchWishlist from "../wishlist_store/lib/fetch";
import dispatchSimilarProducts from "../similar_product_store/lib/fetch";
import dispatchCartBadgeCount from "../cart_badge_count_store/lib/fetch";
import dispatchCheckStock from "../checkstock_store/lib/fetch";
import dispatchCategoryDetail from "../category_detail_store/lib/fetch";
import dispatchAppliedPromotion from "../applied_promotion_store/lib/fetch";
import dispatchHomepage from "../homepage_store/lib/fetch";
import dispatchDeletePromotion from "../delete_promotion_store/lib/fetch";
import dispatchMergeCart from "../merge_cart_store/lib/fetch";
import dispatchMyOrders from "../my_orders_store/lib/fetch";
import dispatchCartTotal from "../cart_total_store/lib/fetch";
import dispatchCheckFlashSalePDP from "../checkflashsalepdp_store/lib/fetch";
// import dispatchLoginPopup from "../login_popup_store/lib/fetch";

export default function* rootSaga() {

	yield fork(dispatchProductDetail);
	yield fork(dispatchSelf);
	yield fork(dispatchCategory);
	yield fork(dispatchCategoryDesktop);
	yield fork(dispatchProducts);
	yield fork(dispatchBrands);
	yield fork(dispatchFlashSale);
	yield fork(dispatchPromotion);
	yield fork(dispatchPromotionDetail);
	yield fork(dispatchPromotionBrowse);
	yield fork(dispatchBanner);
	yield fork(dispatchAddress);
	yield fork(dispatchWishlist);
	yield fork(dispatchSimilarProducts);
	yield fork(dispatchCartBadgeCount);
	yield fork(dispatchCheckStock);
	yield fork(dispatchCategoryDetail);
	yield fork(dispatchAppliedPromotion);
	yield fork(dispatchHomepage);
	yield fork(dispatchDeletePromotion);
	yield fork(dispatchMergeCart);
	yield fork(dispatchMyOrders);
	yield fork(dispatchCartTotal);
	yield fork(dispatchCheckFlashSalePDP);
	// yield fork(dispatchLoginPopup);

}
