import { HYDRATE } from "next-redux-wrapper";
import { ActionsCartBadgeCount } from "./actions";

const initialState = { data: null, status: "fetch_init", message: "" };

function storeCartBadgeCount(state = initialState, action) {

	switch (action.type) {

	case HYDRATE: {

		return { ...state, ...action.payload.storeCartBadgeCount };

	}
	case ActionsCartBadgeCount.LOAD_GET_CART_BADGE_COUNT:
		return { ...state, data: action.data, status: "fetch_loading" };
	case ActionsCartBadgeCount.RES_GET_CART_BADGE_COUNT:
		return { ...state, data: action.data, status: "fetch_result" };
	case ActionsCartBadgeCount.ERR_GET_CART_BADGE_COUNT:
		return {
			...state, data: {}, status: "fetch_error", message: "Error...",
		};
	default: {

		return {
			...state,
		};

	}

	}

}

export default storeCartBadgeCount;
